define("collective-ember-quickstart/initializers/app-scheduler", ["exports", "ember-app-scheduler/initializers/app-scheduler"], function (_exports, _appScheduler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _appScheduler.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _appScheduler.initialize;
    }
  });
});