define("collective-ember-quickstart/components/collective-founders", ["exports", "ember-in-viewport"], function (_exports, _emberInViewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberInViewport.default, {
    didEnterViewport() {
      Ember.$('.founders-cell').each(function (i) {
        Ember.$(this).animate({
          'opacity': '1'
        }, 1000 * i * 2);
      });
    },

    willClearRender() {
      Ember.$('.founders-cell').css('opacity', '0');
    }

  });

  _exports.default = _default;
});