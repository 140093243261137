define("collective-ember-quickstart/templates/components/testimonial-quote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6WwaAhgB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"testimonial-container\"],[9],[0,\"\\n\\t\"],[2,\" <div class=\\\"quote-icon\\\">\\n    {{svg-jar \\\"quote\\\"}}\\n  </div> \"],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"testimonial-copy\"],[9],[0,\"\\n\\t\\t\"],[7,\"p\"],[9],[1,[21,\"body\"],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"divider\"],[9],[10],[0,\"\\n\\t\\t\"],[7,\"span\"],[9],[7,\"p\"],[9],[1,[21,\"attribution\"],false],[10],[10],[0,\"\\n\\t\\t\"],[7,\"p\"],[11,\"class\",\"testimonial-title\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/components/testimonial-quote.hbs"
    }
  });

  _exports.default = _default;
});