define("collective-ember-quickstart/templates/components/collective-founders-alt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eIdBzJQe",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"collective-founders-wrapper founders-alt\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"grid-x\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"cell founders-cell large-6\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n        \"],[7,\"img\"],[12,\"src\",[23,[\"teamMember2\",\"headshot\",\"url\"]]],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"cell founders-cell large-auto\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n          \"],[7,\"h1\"],[9],[1,[23,[\"teamMember2\",\"name\"]],false],[10],[0,\"\\n          \"],[7,\"h2\"],[9],[1,[23,[\"teamMember2\",\"title\"]],false],[10],[0,\"\\n          \"],[7,\"p\"],[9],[1,[23,[\"teamMember2\",\"bio\"]],false],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/components/collective-founders-alt.hbs"
    }
  });

  _exports.default = _default;
});