define("collective-ember-quickstart/routes/insights/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        project: this.store.findAll('project'),
        insight: this.store.query('insight', {
          slug: params.slug
        }).then(function (res) {
          return res.get("firstObject");
        })
      });
    },

    setupController(controller, models) {
      Ember.set(controller, 'projects', models.project);
      Ember.set(controller, 'insight', models.insight);
    },

    activate() {
      var cssClass = this.toCssClass();

      if (cssClass != 'application') {
        Ember.$('body').addClass(cssClass);
      }
    },

    deactivate() {
      Ember.$('body').removeClass(this.toCssClass());
    },

    toCssClass() {
      return this.routeName.replace(/\./g, '-').dasherize() + "-wrapper";
    }

  });

  _exports.default = _default;
});