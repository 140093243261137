define("collective-ember-quickstart/templates/application-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iWaZgpKN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"error-splash\"],[9],[0,\"\\n\"],[4,\"background-video\",null,[[\"mp4\"],[\"https://s3.amazonaws.com/collective-back-end/404.mp4\"]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"error-message\"],[9],[0,\"\\n      \"],[7,\"h1\"],[9],[0,\"Whoa! Something went wrong. Try refreshing the page. If this keeps happening let us know at \"],[7,\"a\"],[11,\"href\",\"mailto:support@thecollective.io\"],[9],[0,\"support@thecollective.io\"],[10],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/application-error.hbs"
    }
  });

  _exports.default = _default;
});