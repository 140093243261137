define("collective-ember-quickstart/components/homepage-carousel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      var target = Ember.$('.homepage-above-fold-container');
      var targetHeight = 650;
      Ember.$(window).on('scroll');
      Ember.$(window).scroll(function () {
        var scrollPercent = (targetHeight - window.scrollY) / targetHeight;

        if (scrollPercent >= 0) {
          target.css('opacity', scrollPercent);
        }
      });
      var timer = null;

      function startTimer() {
        timer = setInterval(function () {
          var $cur = Ember.$('.hero-container').find('.active').fadeOut(800, function () {
            $cur.removeClass('active');
            var $next = $cur.next().length ? $cur.next() : Ember.$('.hero-container').children().eq(0); // console.log($next.find('.svg-container text')[0].getBoundingClientRect().width);

            $next.fadeIn(800, function () {
              $next.addClass('active');
            });
          });
        }, 5000);
      }

      Ember.$('.hero-header ').each(function (index, el) {// console.log($('.hero-header.active').find('.svg-container text').getBoundingClientRect().width);
      }); // console.log($('.hero-header.active').find('.svg-container text')[0].getBoundingClientRect().width);

      Ember.$('.svg-container').hover(function (ev) {
        clearInterval(timer);
        Ember.$('.progress-bar').hide(800);
      }, function (ev) {
        Ember.$('.progress-bar').show(800);
        startTimer();
      });
      startTimer();
    },

    actions: {
      slideToWork: function () {
        Ember.$('html, body').animate({
          scrollTop: Ember.$('.projects-container').offset().top - 50
        }, 800);
      }
    }
  });

  _exports.default = _default;
});