define("collective-ember-quickstart/templates/homepage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xn1Rd2y0",
    "block": "{\"symbols\":[\"firsthomepage\"],\"statements\":[[1,[27,\"liquid-outlet\",null,[[\"enableGrowth\"],[false]]],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"homepage-above-fold-container above-fold-container\"],[9],[0,\"\\n\\n  \"],[2,\" {{#background-video mp4=homepage.firstObject.video.url poster=\\\"/assets/images/video-cover.jpg\\\"}}\\n    {{homepage-carousel homepage=homepage}}\\n  {{/background-video}} \"],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"replacement-image ember-background-video\"],[12,\"style\",[28,[\"background-image: url(\",[23,[\"homepage\",\"firstObject\",\"image\",\"url\"]],\")\"]]],[9],[0,\"\\n    \"],[1,[27,\"homepage-carousel\",null,[[\"homepage\"],[[23,[\"homepage\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[0,\"\\n\\n\\n\\n\"],[7,\"div\"],[11,\"id\",\"past-work\"],[9],[0,\"\\n\"],[4,\"each\",[[27,\"take\",[1,[23,[\"homepage\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"body-text-fade\",null,[[\"header\",\"body\",\"divider\",\"class\"],[[22,1,[\"bodyHeader\"]],[22,1,[\"bodyText\"]],false,\"homepage-body header\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n  \\n  \"],[1,[27,\"project-tiles-study\",null,[[\"projects\"],[[23,[\"casestudy\"]]]]],false],[0,\"\\n\\n  \"],[1,[27,\"project-tiles\",null,[[\"projects\"],[[23,[\"projects\"]]]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"logo-bar-fade\"],[9],[0,\"\\n    \"],[1,[27,\"logo-bar-fade\",null,[[\"companies\",\"header\",\"class\"],[[23,[\"companies\"]],false,\"homepage\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[27,\"contact-callout\",null,[[\"contactHeader\",\"contactBody\",\"color\"],[\"Have an idea and want to talk about it? Get in touch.\",\"Feel free to reach out anytime. We'd love to discuss any upcoming projects you may be interested in.\",\"gray\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/homepage.hbs"
    }
  });

  _exports.default = _default;
});