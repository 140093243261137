define("collective-ember-quickstart/templates/components/contact-callout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A+B8UnAP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"contact-callout-wrapper \",[21,\"color\"]]]],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[1,[21,\"contactHeader\"],false],[10],[0,\"\\n  \"],[7,\"p\"],[9],[1,[21,\"contactBody\"],false],[10],[0,\"\\n  \"],[4,\"link-to\",[\"contact\"],null,{\"statements\":[[1,[27,\"long-button\",null,[[\"buttonText\",\"class\"],[\"Work With Us\",\"contact-callout-button\"]]],false]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/components/contact-callout.hbs"
    }
  });

  _exports.default = _default;
});