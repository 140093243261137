define("collective-ember-quickstart/templates/components/logo-bar-fade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I/aWEn2I",
    "block": "{\"symbols\":[\"company\",\"index\",\"&default\"],\"statements\":[[4,\"if\",[[23,[\"header\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"logo-bar-header\"],[9],[0,\"\\n    \"],[7,\"h2\"],[9],[0,\"\\n      Meet our \"],[7,\"span\"],[11,\"class\",\"clients\"],[9],[0,\"clients\"],[10],[0,\" \"],[7,\"span\"],[11,\"class\",\"friends\"],[9],[0,\"friends.\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\"],[12,\"class\",[28,[\"logo-bar-container grid-x \",[21,\"class\"]]]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"companies\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"cell small-6 medium-4 large-2\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"url\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"target\",\"_blank\"],[12,\"href\",[28,[\"http://\",[22,1,[\"url\"]]]]],[9],[0,\"\\n        \"],[7,\"div\"],[12,\"class\",[28,[\"link-to svg-container \",[22,1,[\"slug\"]]]]],[9],[0,\"\\n          \"],[1,[27,\"get-svg\",[[22,1,[\"logo\",\"url\"]]],[[\"slug\"],[[22,1,[\"slug\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[12,\"class\",[28,[\"svg-container \",[22,1,[\"slug\"]]]]],[9],[0,\"\\n        \"],[1,[27,\"get-svg\",[[22,1,[\"logo\",\"url\"]]],[[\"slug\"],[[22,1,[\"slug\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/components/logo-bar-fade.hbs"
    }
  });

  _exports.default = _default;
});