define("collective-ember-quickstart/helpers/substr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.substr = substr;
  _exports.default = void 0;

  function substr(params, hash) {
    const [value] = params;
    const {
      limit
    } = hash;
    let text = '';

    if (value != null && value.length > 0) {
      text = value.substr(0, limit);

      if (value.length > limit) {
        text += '...';
      }
    }

    return text;
    return params;
  }

  var _default = Ember.Helper.helper(substr);

  _exports.default = _default;
});