define("collective-ember-quickstart/app", ["exports", "collective-ember-quickstart/resolver", "ember-load-initializers", "collective-ember-quickstart/config/environment"], function (_exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  Ember.Logger.warn = () => {};

  Ember.Logger.deprecate = () => {};

  Ember.Router.reopen({
    notifyGoogleAnalytics: Ember.on('didTransition', function () {
      if (!gtag) {
        return;
      }

      return gtag('send', 'pageview', {
        'page': this.url,
        'title': this.url
      });
    })
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});