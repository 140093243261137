define("collective-ember-quickstart/routes/work/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return this.store.query('project', {
        slug: params.slug
      }).then(function (res) {
        return res.get("firstObject");
      });
    },

    setupController(controller, model) {
      Ember.set(controller, 'project', model);
    },

    activate() {
      var cssClass = this.toCssClass();

      if (cssClass != 'application') {
        Ember.$('body').addClass(cssClass);
      }

      Ember.run.later(function () {
        Ember.$('.full-height-hero-wrapper').addClass('fade-in');
      }, 250); // later((function() {
      //   $('.body-text-fade').addClass('fade-in');
      // }), 1050);
      // later((function() {
      //   $('.large-5 > h1').addClass('fade-in');
      // }), 1850);
      // later((function() {
      //   $('.large-5 > p').addClass('fade-in');
      // }), 2150);
      // later((function() {
      //   $('.work-child-button').addClass('fade-in');
      // }), 2450);
    },

    deactivate() {
      Ember.$('body').removeClass(this.toCssClass());
    },

    toCssClass() {
      return this.routeName.replace(/\./g, '-').dasherize() + "-wrapper";
    }

  });

  _exports.default = _default;
});