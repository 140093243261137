define("collective-ember-quickstart/models/teammember", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _emberData.default.Model.extend({
    name: attr('string'),
    title: attr('string'),
    bio: attr('string'),
    headshot: attr()
  });

  _exports.default = _default;
});