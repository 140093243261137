define("collective-ember-quickstart/templates/components/alt-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BRhifgB9",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\\n\"],[7,\"div\"],[12,\"class\",[28,[\"svg-container-alt \",[21,\"class\"]]]],[9],[0,\"\\n   \"],[7,\"a\"],[11,\"class\",\"magic-link\"],[12,\"href\",[28,[\"http://\",[21,\"href\"]]]],[9],[0,\"\\n      \"],[7,\"svg\"],[11,\"class\",\"gradient\"],[11,\"height\",\"60\"],[11,\"width\",\"330\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[9],[0,\"\\n        \"],[7,\"g\"],[9],[0,\"\\n          \"],[7,\"rect\"],[11,\"class\",\"rect-shape-alt\"],[11,\"height\",\"60\"],[11,\"width\",\"330\"],[9],[10],[0,\"\\n          \"],[7,\"text\"],[12,\"x\",[21,\"x\"]],[11,\"y\",\"35\"],[11,\"font-size\",\"35\"],[11,\"class\",\"text\"],[9],[1,[21,\"buttonText\"],false],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n   \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/components/alt-button.hbs"
    }
  });

  _exports.default = _default;
});