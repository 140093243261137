define("collective-ember-quickstart/components/full-height-hero", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      var target = Ember.$('.full-height-hero');
      var targetHeight = 650;
      Ember.$(window).on('scroll');
      Ember.$(window).scroll(function () {
        var scrollPercent = (targetHeight - window.scrollY) / targetHeight;

        if (scrollPercent >= 0) {
          target.css('opacity', scrollPercent);
        }
      });
    },

    willDestroyElement() {
      this.$(window).off('scroll');
    }

  });

  _exports.default = _default;
});