define("collective-ember-quickstart/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rb43b6s6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"global-loader\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"circle-loader-global\"],[9],[10],[0,\"\\n  \"],[1,[27,\"svg-jar\",[\"footer-logo\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/loading.hbs"
    }
  });

  _exports.default = _default;
});