define("collective-ember-quickstart/components/collective-founders-alt", ["exports", "ember-in-viewport"], function (_exports, _emberInViewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberInViewport.default, {
    didRender() {
      // var is_chrome = !!window.chrome && !is_opera;
      var is_explorer = typeof document !== 'undefined' && !!document.documentMode;
      var is_firefox = typeof window.InstallTrigger !== 'undefined';
      var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent); // var is_opera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

      if (is_safari | is_firefox | is_explorer) {
        Ember.$('.founders-cell').each(function (i) {
          Ember.$(this).css({
            'opacity': '1'
          });
        });
      }
    },

    didEnterViewport() {
      Ember.$('.founders-cell').each(function (i) {
        Ember.$(this).animate({
          'opacity': '1'
        }, 1000 * i * 2);
      });
    },

    willClearRender() {
      Ember.$('.founders-cell').css('opacity', '0');
    }

  });

  _exports.default = _default;
});