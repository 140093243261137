define("collective-ember-quickstart/templates/components/three-grid-about", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KI3XM2TQ",
    "block": "{\"symbols\":[\"model\",\"model\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"three-grid-about-wrapper\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"three-grid-about-container grid-x\"],[9],[0,\"\\n\"],[4,\"each\",[[27,\"take\",[1,[23,[\"model\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"h4\"],[11,\"class\",\"three-grid-header\"],[9],[1,[22,2,[\"threeGridHeader\"]],false],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"cell large-4\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"cell-container\"],[9],[0,\"\\n        \"],[7,\"h5\"],[9],[1,[22,1,[\"header\"]],false],[10],[0,\"\\n        \"],[7,\"p\"],[9],[1,[22,1,[\"body\"]],false],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[7,\"h4\"],[11,\"class\",\"toggle-services\"],[9],[0,\"What are we good at?\"],[1,[27,\"svg-jar\",[\"chevron\"],null],false],[3,\"action\",[[22,0,[]],\"expandServices\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"],[14,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/components/three-grid-about.hbs"
    }
  });

  _exports.default = _default;
});