define("collective-ember-quickstart/components/nav-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mobileMenu: false,
    actions: {
      openMobileMenu: function () {
        this.set('mobileMenu', true);
        Ember.run.later(this, function () {
          return this.set('fadeClass', 'fade-in');
        }, 50);
      },
      closeMobileMenu: function () {
        Ember.$('#ham__icon').toggleClass('is-active');
        this.set('fadeClass', '');
        Ember.run.later(this, function () {
          this.set('mobileMenu', false);
        }, 350);
      },
      closeAndScroll: function () {
        Ember.$('#ham__icon').toggleClass('is-active');
        this.set('fadeClass', '');
        this.set('mobileMenu', false);
        Ember.getOwner(this).lookup('router:main').transitionTo('homepage');
        Ember.run.scheduleOnce('afterRender', this, () => {
          Ember.$('html, body').animate({
            scrollTop: Ember.$('.projects-container').offset().top - 50
          }, 800);
        });
      },
      showWork: function () {
        Ember.getOwner(this).lookup('router:main').transitionTo('homepage');
        Ember.run.scheduleOnce('afterRender', this, () => {
          Ember.$('html, body').animate({
            scrollTop: Ember.$('.projects-container').offset().top - 50
          }, 800);
        });
      }
    },

    didInsertElement() {
      var HamIcon = function () {
        this.getElements();
        this.events();
      };

      HamIcon.prototype = {
        getElements: function () {
          this.$ham = Ember.$('#ham__icon');
          this.$hamWrapper = Ember.$('#ham__icon--wrapper');
          this.menuOpenCheck = false;
        },
        events: function () {
          this.$hamWrapper.on('click', this.toggle.bind(this));
          Ember.$(window).on('load', this.checkMenu.bind(this));
        },
        toggle: function () {
          this.$ham.toggleClass('is-active');
        },
        callback: function () {
          if (this.$ham.hasClass('is-open')) {
            sessionStorage.setItem(this.menuOpenCheck, 'open');
          } else {
            sessionStorage.setItem(this.menuOpenCheck, 'close');
          }
        },
        checkMenu: function () {
          if (sessionStorage.getItem(this.menuOpenCheck) == 'open') {
            this.$ham.toggleClass('is-open');
            sessionStorage.setItem(this.menuOpenCheck, 'open');
          }

          if (sessionStorage.getItem(this.menuOpenCheck) == 'close') {
            this.$ham.toggleClass('is-active');
            this.menuOpen = false;
          }
        }
      };
      new HamIcon();
    },

    didRender() {
      var lastScrollTop = 0;
      var delta = 4;
      Ember.$(window).on('scroll', function () {
        hasScrolled();
      });

      function hasScrolled() {
        var st = Ember.$(window).scrollTop(); // Make sure they scroll more than delta

        if (st < 10) {
          Ember.$('#nav').removeClass('nav-up');
          Ember.$('#nav').removeClass('menu-scroll');
        } else {
          if (Math.abs(lastScrollTop - st) <= delta) return;

          if (st > lastScrollTop) {
            // Scroll Down
            if (st > 50) {
              Ember.$('#nav').addClass('nav-up');
              Ember.$('#nav').removeClass('menu-scroll');
            }
          } else {
            // Scroll Up
            if (st + Ember.$(window).height() < Ember.$(document).height() && st > 100) {
              Ember.$('#nav').removeClass('nav-up');
              Ember.$('#nav').addClass('menu-scroll');
            }
          }
        }

        lastScrollTop = st;
      }
    }

  });

  _exports.default = _default;
});