define("collective-ember-quickstart/templates/components/work-hero-callout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/NUozz4j",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"work-hero-callout-wrapper\"],[12,\"style\",[28,[\"height: \",[21,\"height\"]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"bground-image\"],[12,\"style\",[28,[\"background-image: url(\",[21,\"featuredImage\"],\")\"]]],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"image-container\"],[9],[0,\"\\n    \"],[7,\"img\"],[12,\"src\",[21,\"logo\"]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/components/work-hero-callout.hbs"
    }
  });

  _exports.default = _default;
});