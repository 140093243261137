define("collective-ember-quickstart/templates/expertise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AynT0JDL",
    "block": "{\"symbols\":[\"expertise\"],\"statements\":[[7,\"div\"],[11,\"class\",\"expertise-landing-container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"expertise-sub-menu\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"expertise\"],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"arrow\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"right\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[11,\"class\",\"item-container\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"expertise\"]]],null,{\"statements\":[[0,\"      \"],[4,\"link-to\",[\"expertise.show\",[22,1,[\"slug\"]]],null,{\"statements\":[[1,[22,1,[\"slug\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\\n    \"],[2,\" <p {{action 'closeSubMenu'}}>Close</p> \"],[0,\"\\n  \"],[10],[0,\"\\n  \"],[1,[27,\"liquid-outlet\",null,[[\"enableGrowth\"],[false]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/expertise.hbs"
    }
  });

  _exports.default = _default;
});