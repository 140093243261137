define("collective-ember-quickstart/templates/expertise/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VCRfoHZ4",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"liquid-outlet\"],false],[0,\"\\n\\n\"],[1,[27,\"expertise-toolbar\",null,[[\"expertiseheader\",\"expertise\"],[[23,[\"expertiseheader\"]],[23,[\"expertise\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/expertise/index.hbs"
    }
  });

  _exports.default = _default;
});