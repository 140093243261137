define("collective-ember-quickstart/components/project-animation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function () {
      var projects = this.results;

      var MorphingDevice = function (el, settings) {
        var transEndEventNames = {
          'WebkitTransition': 'webkitTransitionEnd',
          'MozTransition': 'transitionend',
          'OTransition': 'oTransitionEnd',
          'msTransition': 'MSTransitionEnd',
          'transition': 'transitionend'
        },
            transEndEventName = transEndEventNames,
            device = el.querySelector('div.md-device'),
            current = 0,
            rotated = false,
            rotateDevice = document.createElement('button'),
            img = device.querySelector('a > div');

        function init() {
          rotateDevice.style.display = 'none';

          rotateDevice.onclick = function () {
            device.className = rotated ? device.className.replace(/\b md-rotated\b/, '') : device.className + ' md-rotated'; // change images

            var newsrc = rotated ? settings.devices[current].imgsrc : settings.devices[current].rotatedsrc;
            changeImages(newsrc);
            rotated = !rotated;
            return false;
          };

          device.appendChild(rotateDevice);
        }

        function changeDevice() {
          device.className = 'md-device ' + settings.devices[current].cName;
          rotated = false;
          rotateDevice.style.display = settings.devices[current].canRotate ? '' : 'none'; // change images

          changeImages(settings.devices[current].imgsrc);
        }

        function changeImages(newsrc) {
          var imgwrapper = img.parentNode,
              newimg = document.createElement('div');
          newimg.setAttribute('style', 'background-image: url("' + newsrc + '");');

          if (imgwrapper.childNodes.length > 1) {
            imgwrapper.removeChild(imgwrapper.firstChild);
          }

          imgwrapper.insertBefore(newimg, img);
          img.className = 'md-fadeout';
          img.addEventListener(transEndEventName, function () {
            img = newimg;

            if (this.parentNode != null) {
              this.parentNode.removeChild(this);
            }
          }, false);
        }

        function setCurrent(c) {
          current = c;
        }

        function getCurrent() {
          return current;
        }

        init();
        return {
          init: init,
          setCurrent: setCurrent,
          getCurrent: getCurrent,
          changeDevice: changeDevice
        };
      };

      var scrollDevices = function () {
        var devices = []; //Make our Devices Object

        Ember.$(projects).each(function (index) {
          if (projects[index] !== undefined) {
            devices.push({
              cName: 'md-device-' + projects[index].id,
              canRotate: false,
              imgsrc: projects[index].image.url
            });
          }
        });
        var el = document.querySelector('.md-slider'),
            settings = {
          devices: devices
        },
            devicesTotal = settings.devices.length,
            ds = MorphingDevice(el, settings),
            projHeight,
            scrollBreak = [];

        function calcProjHeight() {
          var scrollArray = [];
          projHeight = Ember.$(window).outerHeight() * devicesTotal;

          if (screenW > 800) {
            Ember.$('.project-container').height(projHeight);
          }

          for (var i = 0; i < devicesTotal; i++) {
            scrollArray.push(Ember.$('.project-container').offset().top + projHeight * (i / devicesTotal));
          }

          return scrollArray;
        }

        function between(x, min, max) {
          if (max == undefined) {
            return x >= min;
          } else {
            return x >= min && x <= max;
          }
        }

        var deviceMove;
        var ScrollTop;
        var deviceNumber;
        var min;
        var max;
        var header = projects[0].header;
        var textbody = projects[0].body;
        var screenW = Ember.$(window).outerWidth();
        var contact = Ember.$('.contact-callout-wrapper');
        var testimonial = Ember.$('.testimonial-container');
        var scrollStopPoint;
        var offset;
        Ember.$(window).on('load scroll resize', function (e) {
          screenW = Ember.$(window).outerWidth();
          var testAbs = Ember.$('.project-container').offset().top + Ember.$('.project-container').outerHeight() - Ember.$('.device-container').outerHeight() - 200;
          scrollBreak = calcProjHeight();

          if (screenW < 800) {
            Ember.$('.project-container').outerHeight('auto');
          } else {
            Ember.$('.project-container').outerHeight(Ember.$(window).outerHeight() * devicesTotal);

            if (testimonial.length) {
              scrollStopPoint = testimonial.offset().top;
              offset = contact.height() + Ember.$('.footer-containter').height() + testimonial.height();
            } else {
              scrollStopPoint = contact.offset().top;
              offset = contact.outerHeight() + Ember.$('.footer-containter').outerHeight();
            }
          }

          ScrollTop = Ember.$(window).scrollTop();
          deviceMove = Ember.$('.md-device-wrapper').offset().top;

          if (scrollBreak[0] > ScrollTop) {
            Ember.$('.scroll-wrapper').removeClass('scroller');
          } else {
            Ember.$('.scroll-wrapper').addClass('scroller');
          }

          Ember.$(scrollBreak).each(function (index) {
            min = scrollBreak[index];
            max = scrollBreak[index + 1];

            if (index == 0) {
              min = 0;
              deviceNumber = 0;
              header = projects[index].header;
              textbody = projects[index].body;
            } else if (index == 1) {
              min = scrollBreak[1];
              deviceNumber = 1;
              header = projects[index].header;
              textbody = projects[index].body;
            } else if (index == 2) {
              min = scrollBreak[2];
              deviceNumber = 2;
              header = projects[index].header;
              textbody = projects[index].body;
            } else if (index == 3) {
              min = scrollBreak[3];
              deviceNumber = 3;
              header = projects[index].header;
              textbody = projects[index].body;
            } else if (index == 4) {
              min = scrollBreak[4];
              deviceNumber = 4;
              header = projects[index].header;
              textbody = projects[index].body;
            }

            if (max == undefined) {
              max = Ember.$(document).outerHeight();
            }

            if (between(deviceMove, min, max)) {
              ds.setCurrent(deviceNumber);
              Ember.$('.text-block h2').html(header);
              Ember.$('.text-block p').html(textbody);
              ds.changeDevice();
            }
          }); //Start + Stop

          if (ScrollTop > testAbs) {
            Ember.$('.scroll-wrapper.scroller').css({
              top: testAbs + 'px',
              position: 'absolute'
            });
          } else if (scrollBreak[0] > ScrollTop) {
            Ember.$('.scroll-wrapper').css({
              top: '',
              position: 'relative'
            });
          } else {
            Ember.$('.scroll-wrapper.scroller').css({
              top: 0,
              position: 'fixed'
            });
          }
        });
      };

      scrollDevices();
    },

    willDestroyElement() {
      this.$(document).off('scroll resize');
    }

  });

  _exports.default = _default;
});