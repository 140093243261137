define("collective-ember-quickstart/models/hosting", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _emberData.default.Model.extend({
    calloutHeader: attr('string'),
    calloutBody: attr('string'),
    calloutImage1: attr(),
    calloutImage2: attr(),
    calloutImage3: attr()
  });

  _exports.default = _default;
});