define("collective-ember-quickstart/components/liquid-append", ["exports", "liquid-wormhole/components/liquid-append"], function (_exports, _liquidAppend) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _liquidAppend.default;
    }
  });
});