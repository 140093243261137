define("collective-ember-quickstart/templates/components/project-tiles-study", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pFV3l6Zy",
    "block": "{\"symbols\":[\"project\",\"index\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"id\",\"projects-container\"],[11,\"class\",\"projects-container\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"grid projects-grid\"],[9],[0,\"\\n\"],[4,\"each\",[[27,\"sort-by\",[\"projectOrder\",[23,[\"projects\"]]],null]],null,{\"statements\":[[4,\"if\",[[22,1,[\"readymagLink\"]]],null,{\"statements\":[[0,\"        \"],[7,\"a\"],[12,\"href\",[22,1,[\"readymagLink\"]]],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"grid-item grid-item-4\"],[12,\"style\",[28,[\"background-image: url(\",[22,1,[\"heroImage\",\"url\"]],\")\"]]],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"grid-item-overlay\"],[9],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"grid-item-inset\"],[9],[10],[0,\"\\n            \"],[7,\"div\"],[12,\"class\",[28,[\"svg-container-project \",[22,1,[\"slug\"]],\" case-study-logo\"]]],[9],[0,\"\\n              \"],[1,[27,\"get-svg\",[[22,1,[\"logo\",\"url\"]]],[[\"slug\"],[[22,1,[\"slug\"]]]]],false],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/components/project-tiles-study.hbs"
    }
  });

  _exports.default = _default;
});