define("collective-ember-quickstart/components/blog-tiles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender() {
      var $grid = Ember.$('.grid').isotope({
        itemSelector: '.grid-item'
      });
      Ember.$('.filter-button-group').on('click tap touch', 'p', function () {
        var filterValue = Ember.$(this).attr('data-filter');
        $grid.isotope({
          filter: filterValue
        });
      });
    }

  });

  _exports.default = _default;
});