define("collective-ember-quickstart/routes/about", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        about: this.store.findAll('about'),
        expertise: this.store.findAll('expertise'),
        company: this.store.findAll('company'),
        teamMember: this.store.findAll('teammember'),
        threeGridOverview: this.store.findAll('threeGridOverview')
      });
    },

    setupController(controller, models) {
      controller.set('about', models.about);
      controller.set('expertise', models.expertise);
      controller.set('companies', models.company);
      controller.set('teamMember', models.teamMember);
      controller.set('threeGridOverview', models.threeGridOverview);
    },

    activate() {
      var cssClass = this.toCssClass();

      if (cssClass != 'application') {
        Ember.$('body').addClass(cssClass);
      }

      Ember.run.later(function () {
        Ember.$('.full-height-hero-wrapper').addClass('fade-in');
      }, 250);
      Ember.run.later(function () {
        Ember.$('.header-body-text-container > h1').addClass('fade-in');
      }, 250);
      Ember.run.later(function () {
        Ember.$('.header-body-text-container > p').addClass('fade-in');
      }, 250);
    },

    deactivate() {
      Ember.$('body').removeClass(this.toCssClass());
    },

    toCssClass() {
      return this.routeName.replace(/\./g, '-').dasherize() + "-wrapper";
    }

  });

  _exports.default = _default;
});