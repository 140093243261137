define("collective-ember-quickstart/routes/expertise/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        expertise: this.store.findAll('expertise'),
        expertiseheader: this.store.findAll('expertiseheader')
      });
    },

    setupController(controller, models) {
      controller.set('expertise', models.expertise);
      controller.set('expertiseheader', models.expertiseheader);
    },

    activate() {
      Ember.run.later(function () {
        Ember.$('.expertise-index-container').addClass('fade-in');
      }, 250);
      var cssClass = this.toCssClass();

      if (cssClass != 'application') {
        Ember.$('body').addClass(cssClass);
      }
    },

    deactivate() {
      Ember.$('body').removeClass(this.toCssClass());
    },

    toCssClass() {
      return this.routeName.replace(/\./g, '-').dasherize() + "-wrapper";
    }

  });

  _exports.default = _default;
});