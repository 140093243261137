define("collective-ember-quickstart/router", ["exports", "collective-ember-quickstart/config/environment", "ember-router-scroll"], function (_exports, _environment, _emberRouterScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    redirects: {
      'promotion': 'homepage'
    }
  });
  Router.map(function () {
    this.route('404', {
      path: '/*path'
    });
    this.route('homepage', {
      path: '/'
    });
    this.route('about');
    this.route('work', function () {
      this.route('show', {
        path: '/:slug'
      });
    });
    this.route('insights', function () {
      this.route('show', {
        path: '/:slug'
      });
    });
    this.route('expertise', function () {
      this.route('show', {
        path: '/:slug'
      });
    });
    this.route('contact');
    this.route('progressive-web-apps');
    this.route('promotion');
  });
  var _default = Router;
  _exports.default = _default;
});