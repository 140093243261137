define("collective-ember-quickstart/models/insight", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _emberData.default.Model.extend({
    author: attr('string'),
    category: attr('string'),
    title: attr('string'),
    publishDate: attr('date'),
    createdAt: attr('date'),
    body: attr('string'),
    featuredImage: attr(),
    featurePost: attr('boolean'),
    slug: attr('string'),
    showFeaturedProjects: attr('boolean')
  });

  _exports.default = _default;
});