define("collective-ember-quickstart/routes/expertise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.store.findAll('expertise');
    },

    setupController(controller, model) {
      Ember.set(controller, 'expertise', model);
    },

    activate() {
      var cssClass = this.toCssClass();

      if (cssClass != 'application') {
        Ember.$('body').addClass(cssClass);
      }
    },

    deactivate() {
      Ember.$('body').removeClass(this.toCssClass());
    },

    toCssClass() {
      return this.routeName.replace(/\./g, '-').dasherize() + "-wrapper";
    }

  });

  _exports.default = _default;
});