define("collective-ember-quickstart/models/company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _emberData.default.Model.extend({
    companyName: attr('string'),
    url: attr('string'),
    logo: attr(),
    slug: attr('string')
  });

  _exports.default = _default;
});