define("collective-ember-quickstart/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    // var duration = 850;
    var slideDuration = 750; // this.transition(
    //   this.useAndReverse('fade', {duration: duration})
    // );

    this.transition(this.fromRoute('expertise.index'), this.toRoute('expertise.show'), this.use('toLeft', {
      duration: slideDuration
    }), this.reverse('toRight', {
      duration: slideDuration
    }));
  }
});