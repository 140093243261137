define("collective-ember-quickstart/templates/components/hosting-callout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "REr+L3vj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"hosting-callout-wrapper\"],[12,\"style\",[28,[[27,\"if\",[[23,[\"backgroundImage\"]],\"background-image\",\"\"],null],\": url(\",[21,\"hero\"],\")\"]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"grid-x\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"cell large-7\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"center-container\"],[9],[0,\"\\n          \"],[7,\"h2\"],[9],[1,[21,\"calloutHeader\"],false],[10],[0,\"\\n          \"],[7,\"p\"],[12,\"class\",[27,\"if\",[[23,[\"link\"]],\"link\"],null]],[9],[1,[21,\"calloutBody\"],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"link\"]]],null,{\"statements\":[[0,\"          \"],[7,\"a\"],[11,\"target\",\"_blank\"],[12,\"href\",[21,\"linkHref\"]],[9],[1,[21,\"linkText\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"cell large-auto\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"center-container\"],[9],[0,\"\\n          \"],[7,\"div\"],[12,\"class\",[28,[\"grid-x \",[21,\"class\"]]]],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"cell medium-6 large-6\"],[9],[0,\"\\n              \"],[7,\"img\"],[11,\"draggable\",\"false\"],[12,\"src\",[23,[\"calloutImage1\",\"url\"]]],[9],[10],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"cell medium-6 large-6\"],[9],[0,\"\\n              \"],[7,\"img\"],[11,\"class\",\"aws\"],[11,\"draggable\",\"false\"],[12,\"src\",[23,[\"calloutImage2\",\"url\"]]],[9],[10],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"img\"],[11,\"draggable\",\"false\"],[12,\"src\",[23,[\"calloutImage3\",\"url\"]]],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/components/hosting-callout.hbs"
    }
  });

  _exports.default = _default;
});