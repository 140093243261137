define("collective-ember-quickstart/templates/components/expertise-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4wOIPJA5",
    "block": "{\"symbols\":[\"expertise\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"about-expertise-wrapper\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"grid-x\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"expertise\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"cell large-3\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"centered\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"icon\"],[9],[0,\"\\n            \"],[7,\"img\"],[11,\"draggable\",\"false\"],[12,\"src\",[22,1,[\"featuredImage\",\"url\"]]],[9],[10],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"h3\"],[9],[1,[22,1,[\"slug\"]],false],[10],[0,\"\\n          \"],[7,\"p\"],[9],[1,[27,\"substr\",[[22,1,[\"overview\"]]],[[\"limit\"],[150]]],false],[10],[0,\"\\n          \"],[4,\"link-to\",[\"expertise.show\",[22,1,[\"slug\"]]],null,{\"statements\":[[0,\"Learn More\"],[1,[27,\"svg-jar\",[\"chevron\"],null],false]],\"parameters\":[]},null],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/components/expertise-overview.hbs"
    }
  });

  _exports.default = _default;
});