define("collective-ember-quickstart/templates/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C8TgSzPd",
    "block": "{\"symbols\":[\"contact\"],\"statements\":[[4,\"each\",[[23,[\"contact\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"contact-page-wrapper\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"contact-page-container grid-x\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"cell large-5\"],[9],[0,\"\\n        \"],[7,\"h1\"],[9],[1,[22,1,[\"header\"]],false],[10],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"contact-body\"],[9],[0,\"\\n          \"],[1,[27,\"markdown-render\",[[22,1,[\"body\"]]],null],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"cell large-auto\"],[9],[0,\"\\n        \"],[7,\"img\"],[11,\"draggable\",\"false\"],[12,\"src\",[22,1,[\"featuredImage\",\"url\"]]],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"contact-form\"],[9],[0,\"\\n    \"],[1,[27,\"contact-form\",null,[[\"header\",\"subHeader\",\"phoneHeader\",\"emailHeader\"],[[22,1,[\"formHeader\"]],[22,1,[\"formSubHeader\"]],[22,1,[\"formPhoneNumber\"]],[22,1,[\"formEmail\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\\n\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/contact.hbs"
    }
  });

  _exports.default = _default;
});