define("collective-ember-quickstart/components/three-grid-about", ["exports", "ember-in-viewport"], function (_exports, _emberInViewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberInViewport.default, {
    actions: {
      expandServices: function () {
        Ember.$('.about-expertise-wrapper').slideToggle('slow');
        Ember.$('.toggle-services > svg').toggleClass('open-caret');
      }
    },

    didRender() {
      // var is_chrome = !!window.chrome && !is_opera;
      var is_explorer = typeof document !== 'undefined' && !!document.documentMode;
      var is_firefox = typeof window.InstallTrigger !== 'undefined';
      var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent); // var is_opera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

      if (is_safari | is_firefox | is_explorer) {
        Ember.$('.three-grid-header').css({
          'opacity': '1'
        });
        Ember.$('.toggle-services').css({
          'opacity': '1'
        });
        Ember.$('.cell-container').each(function (i) {
          Ember.$(this).css({
            'opacity': '1'
          });
        });
      }
    },

    didEnterViewport() {
      Ember.$('.three-grid-header').animate({
        'opacity': '1'
      }, 1500);
      Ember.$('.cell-container').each(function (i) {
        Ember.$(this).animate({
          'opacity': '1'
        }, 1500 * i * 2 / 900);
      });
      Ember.$('.toggle-services').animate({
        'opacity': '1'
      }, 3000);
    },

    willClearRender() {
      Ember.$('.three-grid-header').css('opacity', '0');
      Ember.$('.cell-container').each(function (i) {
        Ember.$(this).css('opacity', '0');
      });
      Ember.$('.toggle-services').css('opacity', '0');
    }

  });

  _exports.default = _default;
});