define("collective-ember-quickstart/templates/components/header-body-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i+iex5f9",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"header-body-text-container \",[21,\"class\"]]]],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[1,[21,\"header\"],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"divider\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"svg-jar\",[\"divider-one\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"p\"],[12,\"class\",[28,[[27,\"if\",[[23,[\"divider\"]],\"divider\",\"top-margin\"],null]]]],[9],[1,[21,\"body\"],false],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/components/header-body-text.hbs"
    }
  });

  _exports.default = _default;
});