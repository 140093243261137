define("collective-ember-quickstart/models/development", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _emberData.default.Model.extend({
    mobileResponsiveImg: attr(),
    mobileResponsiveBackgroundImg: attr(),
    mobileResponsiveHeader: attr('string'),
    mobileResponsiveBody: attr('string'),
    PWACalloutHeader: attr('string'),
    PWACalloutBody: attr('string'),
    PWACalloutImg: attr(),
    PWACalloutBackground: attr()
  });

  _exports.default = _default;
});