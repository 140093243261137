define("collective-ember-quickstart/helpers/get-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSvg = getSvg;
  _exports.default = void 0;

  function getSvg(params, hash) {
    var returnData = Ember.$.ajax({
      type: "GET",
      url: params,
      async: false
    }).responseText;
    Ember.$('.' + hash.slug).html(returnData);
  }

  var _default = Ember.Helper.helper(getSvg);

  _exports.default = _default;
});