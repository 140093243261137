define("collective-ember-quickstart/routes/expertise/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        techwelike: this.store.findAll('techwelike'),
        hosting: this.store.findAll('hosting'),
        development: this.store.findAll('development'),
        membership: this.store.findAll('membership'),
        expertise: this.store.query('expertise', {
          slug: params.slug
        }).then(function (res) {
          return res.get("firstObject");
        })
      });
    },

    setupController(controller, models) {
      Ember.set(controller, 'expertise', models.expertise);
      Ember.set(controller, 'techwelike', models.techwelike);
      Ember.set(controller, 'hosting', models.hosting);
      Ember.set(controller, 'membership', models.membership);
      Ember.set(controller, 'development', models.development);
    },

    activate() {
      Ember.$('.expertise-sub-menu').addClass('top-down');
      var cssClass = this.toCssClass();

      if (cssClass != 'application') {
        Ember.$('body').addClass(cssClass);
      }

      Ember.run.later(function () {
        Ember.$('.expertise-sub-menu').addClass('top-down');
      }, 10);
      Ember.run.later(function () {
        Ember.$('.expertise-show-container').addClass('fade-in');
      }, 550);
    },

    deactivate() {
      Ember.$('body').removeClass(this.toCssClass());
      Ember.$('.expertise-sub-menu').removeClass('top-down');
      Ember.$('.expertise-show-container').removeClass('fade-in');
    },

    toCssClass() {
      return this.routeName.replace(/\./g, '-').dasherize() + "-wrapper";
    }

  });

  _exports.default = _default;
});