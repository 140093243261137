define("collective-ember-quickstart/templates/components/image-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5iIFWVfY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"image-list-wrapper\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"image-list-container grid-x\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"cell large-7 subImage\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"draggable\",\"false\"],[12,\"src\",[21,\"subImage\"]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"cell expertise-list large-auto\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"list\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"markdown-render\",[[23,[\"list\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/components/image-list.hbs"
    }
  });

  _exports.default = _default;
});