define("collective-ember-quickstart/templates/components/work-tech-we-like", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XBZmwdPu",
    "block": "{\"symbols\":[\"tech\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"tech-we-like-wrapper\"],[9],[0,\"\\n  \"],[7,\"h4\"],[9],[0,\"Technology we used.\"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"grid-x\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"techwelike\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"cell small-6 medium-2 large-2\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"center-container\"],[9],[0,\"\\n        \"],[7,\"img\"],[12,\"src\",[22,1,[\"url\"]]],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/components/work-tech-we-like.hbs"
    }
  });

  _exports.default = _default;
});