define("collective-ember-quickstart/components/contact-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      emailjs.init("user_pWMPjK77fwBu8FYax4WNT");
      Ember.$('.slide-form').on('click tap touch', function () {
        Ember.$('.right').toggleClass('right-grow');
        Ember.$('.down-caret').toggleClass('open-caret');
      });
      Ember.$('.contact-body > p > strong').on('click tap touch', function () {
        Ember.$('html, body').animate({
          scrollTop: Ember.$('.contact-form').offset().top - 50
        }, 800);
      });
    },

    emailSent: false,
    fade: false,
    error: true,
    serverError: false,
    loading: false,
    actions: {
      sendEmail: function () {
        var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
        var self = this;
        var emailInputValue = Ember.$(".email-input").val();

        if (!Ember.$(".name-input").val()) {
          self.set('error', true);
          Ember.$(".name-input").addClass('error');
        } else {
          self.set('error', false);
          Ember.$(".name-input").removeClass('error');
        }

        if (!pattern.test(emailInputValue)) {
          self.set('error', true);
          Ember.$(".email-input").val("");
          Ember.$(".email-input").attr("placeholder", "Please enter a valid email address.");
          Ember.$(".email-input").addClass('error');
        } else {
          self.set('error', false);
          Ember.$(".email-input").attr("placeholder", "");
          Ember.$(".email-input").removeClass('error');
        }

        if (!Ember.$(".company-input").val()) {
          self.set('error', true);
          Ember.$(".company-input").addClass('error');
        } else {
          self.set('error', false);
          Ember.$(".company-input").removeClass('error');
        }

        if (!Ember.$(".phone-input").val()) {
          self.set('error', true);
          Ember.$(".phone-input").addClass('error');
        } else {
          self.set('error', false);
          Ember.$(".phone-input").removeClass('error');
        }

        if (!Ember.$(".message-input").val()) {
          self.set('error', true);
          Ember.$(".message-input").addClass('error');
        } else {
          self.set('error', false);
          Ember.$(".message-input").removeClass('error');
        }

        if (self.get('error') === true) {
          Ember.$('.form-entry').text("Please fill out all required fields in order to submit the form...").addClass('error-h2');
        }

        var email = this.email;
        var name = this.name;
        var company = this.company;
        var phone = this.phone;
        var message = this.message;

        if (this.error === false) {
          this.toggleProperty('loading');
          emailjs.send("sendgrid", "1", {
            "reply_to": email,
            "from_name": name,
            "company": company,
            "phone": phone,
            "message_html": message
          }).then(function (response) {
            Ember.$('.circle-loader').toggleClass('load-complete');
            Ember.$('.checkmark').toggle();
            Ember.run.later(function () {
              self.toggleProperty('loading');
              self.toggleProperty('fade');
              self.toggleProperty('emailSent');
            }, 1000);
          }, function (error) {
            self.toggleProperty('serverError');
          });
        }
      },
      sendAnotherMessage: function () {
        this.toggleProperty('fade');
        this.toggleProperty('emailSent');
        this.set('email', '');
        this.set('message', '');
        this.set('phone', '');
        this.set('company', '');
        this.set('name', '');
      }
    }
  });

  _exports.default = _default;
});