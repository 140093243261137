define("collective-ember-quickstart/controllers/work/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    someProperty: Ember.computed.readOnly('project._internalModel.__data'),
    results: Ember.computed('project', function () {
      return getMockup(this.get('project._internalModel.__recordData.__data'));
    })
  });

  _exports.default = _default;

  function getMockup(data) {
    var projectData = data;
    var sortOrder = projectData.orderMockups;
    var mockups = [];
    var hasNumber = /\d/;
    var header;
    var body;
    var image;

    for (var property in projectData) {
      if (hasNumber.test(property)) {
        if (property.match(/Header+/)) {
          header = projectData[property];
        } else if (property.match(/Body+/)) {
          body = projectData[property];
        } else if (property.match(/Image+/)) {
          image = projectData[property];
        }

        if (image == "" || body == "" || header == null) {
          mockups[property.match(/\d+/)[0]] = null;
        } else {
          mockups[property.match(/\d+/)[0]] = {
            id: property.match(/\d+/)[0],
            header: header,
            body: body,
            image: image
          };
        }
      }
    }

    var mockupSelection = [];
    sortOrder.forEach(function (index, el) {
      mockupSelection[index] = mockups[index];
    });
    var data = mockupSelection,
        orderO = {};
    sortOrder.forEach(function (a, i, aa) {
      orderO[a] = i - aa.length; // use negative values and zero as default
    });
    data.sort(function (a, b) {
      return (orderO[a.id] || 0) - (orderO[b.id] || 0) || a.title.localeCompare(b.title);
    });
    return data;
  }
});