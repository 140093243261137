define("collective-ember-quickstart/adapters/application", ["exports", "ember-data", "ember-get-config", "ember-inflector"], function (_exports, _emberData, _emberGetConfig, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend({
    host: _emberGetConfig.default.cmsAPI,
    pathForType: function (type) {
      var camelized = Ember.String.camelize(type);
      return (0, _emberInflector.singularize)(camelized);
    }
  });

  _exports.default = _default;
});