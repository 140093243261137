define("collective-ember-quickstart/controllers/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      showContact: function () {
        Ember.$('html, body').animate({
          scrollTop: Ember.$('.contact-form').offset().top - 50
        }, 800);
      }
    }
  });

  _exports.default = _default;
});