define("collective-ember-quickstart/templates/components/site-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xtm0/3I7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"footer-containter\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"homepage\"],null,{\"statements\":[[0,\"    \"],[1,[27,\"svg-jar\",[\"footer-logo\"],[[\"class\"],[\"logo\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"h4\"],[9],[0,\"Collective © Twenty Twenty One\"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"social-icons-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"social-icons\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"href\",\"https://www.facebook.com/the.collective.io/\"],[11,\"target\",\"_blank\"],[9],[1,[27,\"svg-jar\",[\"facebook-logo\"],null],false],[10],[0,\"\\n      \"],[7,\"a\"],[11,\"href\",\"https://www.linkedin.com/company/the-collective-development-agency/\"],[11,\"target\",\"_blank\"],[9],[1,[27,\"svg-jar\",[\"linkedin-logo\"],null],false],[10],[0,\"\\n      \"],[2,\" <a href=\\\"https://twitter.com/collective_io\\\" target=\\\"_blank\\\">{{svg-jar \\\"twitter-logo\\\"}}</a> \"],[0,\"\\n      \"],[7,\"a\"],[11,\"href\",\"https://www.instagram.com/the.collective.io/\"],[11,\"target\",\"_blank\"],[9],[1,[27,\"svg-jar\",[\"instagram-logo\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/components/site-footer.hbs"
    }
  });

  _exports.default = _default;
});