define("collective-ember-quickstart/instance-initializers/init-app-scheduler", ["exports", "ember-app-scheduler/instance-initializers/init-app-scheduler"], function (_exports, _initAppScheduler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _initAppScheduler.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function () {
      return _initAppScheduler.initialize;
    }
  });
});