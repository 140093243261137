define("collective-ember-quickstart/components/expertise-toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender() {
      Ember.$('.expertise-tool-container a').mouseover(function () {
        Ember.$('.main-entry-header').hide();
        var toShow = '#' + Ember.$(this).attr('rel');
        Ember.$(toShow).show();
      }).mouseout(function () {
        Ember.$('.expertise-tool-info > div').hide();
        Ember.$('.main-entry-header').show();
      });
    }

  });

  _exports.default = _default;
});