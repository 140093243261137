define("collective-ember-quickstart/instance-initializers/redirect", ["exports", "ember-redirect/utils/reopen-route", "ember-redirect/utils/container"], function (_exports, _reopenRoute, _container) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'redirect',

    initialize(instance) {
      let router = (0, _container.lookup)(instance, 'router:main');
      let routeNames = router && router.redirects ? Object.keys(router.redirects) : []; // The user has specified the redirects on the router object instead of the map function
      // so we should use those instead of invoking the map function

      if (router.redirects && routeNames.length > 0) {
        routeNames.forEach(function (routeName) {
          (0, _reopenRoute.default)(routeName, {
            redirect: router.redirects[routeName]
          }, instance);
        });
      }
    }

  };
  _exports.default = _default;
});