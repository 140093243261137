define("collective-ember-quickstart/models/expertiseheader", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _emberData.default.Model.extend({
    header: attr('string'),
    body: attr('string'),
    featuredImage: attr()
  });

  _exports.default = _default;
});