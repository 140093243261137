define("collective-ember-quickstart/routes/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return this.store.findAll('contact');
    },

    setupController(controller, model) {
      Ember.set(controller, 'contact', model);
    },

    activate() {
      Ember.run.later(function () {
        Ember.$('.contact-page-wrapper').addClass('fade-in');
      }, 250);
      var cssClass = this.toCssClass();

      if (cssClass != 'application') {
        Ember.$('body').addClass(cssClass);
      }
    },

    deactivate() {
      Ember.$('body').removeClass(this.toCssClass());
    },

    toCssClass() {
      return this.routeName.replace(/\./g, '-').dasherize() + "-wrapper";
    }

  });

  _exports.default = _default;
});