define("collective-ember-quickstart/routes/homepage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { set } from '@ember/object'
  var _default = Ember.Route.extend({
    model() {
      return Ember.RSVP.hash({
        homepage: this.store.findAll('homepage'),
        project: this.store.findAll('project'),
        company: this.store.findAll('company'),
        casestudy: this.store.findAll('casestudy')
      });
    },

    setupController(controller, models) {
      controller.set('homepage', models.homepage);
      controller.set('projects', models.project);
      controller.set('companies', models.company);
      controller.set('casestudy', models.casestudy);
    },

    activate() {
      var cssClass = this.toCssClass();

      if (cssClass != 'application') {
        Ember.$('body').addClass(cssClass);
      }
    },

    deactivate() {
      Ember.$('body').removeClass(this.toCssClass());
    },

    toCssClass() {
      return this.routeName.replace(/\./g, '-').dasherize() + "-wrapper";
    }

  });

  _exports.default = _default;
});