define("collective-ember-quickstart/models/project", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _emberData.default.Model.extend({
    projectOrder: attr('number'),
    companyName: attr('string'),
    featuredImageOverlay: attr('boolean'),
    featuredImage: attr(),
    slug: attr('string'),
    companyLogo: attr(),
    shortBlurb: attr('string'),
    animate: attr('boolean'),
    pngLogo: attr(),
    siteUrl: attr('string'),
    workTypeHeader: attr('string'),
    cont: _emberData.default.attr('array'),
    projectOverview: attr('string'),
    altFeaturedImg: attr(),
    alternateCallout: attr('boolean'),
    testimonialBody: attr('string'),
    testimonialAttribution: attr('string'),
    testimonialAttributionTitle: attr('string'),
    projectHeader0: attr('string'),
    projectBody0: attr('string'),
    projectImage0: attr(),
    projectHeader1: attr('string'),
    projectBody1: attr('string'),
    projectImage1: attr(),
    projectHeader2: attr('string'),
    projectBody2: attr('string'),
    projectImage2: attr(),
    projectHeader3: attr('string'),
    projectBody3: attr('string'),
    projectImage3: attr(),
    projectHeader4: attr('string'),
    projectBody4: attr('string'),
    projectImage4: attr(),
    orderMockups: attr(),
    techLogos: attr()
  });

  _exports.default = _default;
});