define("collective-ember-quickstart/templates/components/tech-we-like", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MH3ijIRi",
    "block": "{\"symbols\":[\"tech\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"tech-we-like-wrapper\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[0,\"Technology we \"],[7,\"span\"],[9],[0,\"like\"],[10],[0,\" love.\"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"grid-x\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"techwelike\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"cell small-6 medium-6 large-2\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"target\",\"_blank\"],[12,\"href\",[28,[\"http://\",[22,1,[\"url\"]]]]],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"center-container\"],[9],[0,\"\\n          \"],[7,\"img\"],[12,\"src\",[22,1,[\"logo\",\"url\"]]],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\\n\\n\\n\\n\"],[14,2],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/components/tech-we-like.hbs"
    }
  });

  _exports.default = _default;
});