define("collective-ember-quickstart/templates/components/project-tiles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sqbh7DHN",
    "block": "{\"symbols\":[\"project\",\"index\",\"&default\"],\"statements\":[[7,\"div\"],[11,\"id\",\"projects-container\"],[11,\"class\",\"projects-container\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"grid grid-x projects-grid\"],[9],[0,\"\\n\"],[4,\"each\",[[27,\"sort-by\",[\"projectOrder\",[23,[\"projects\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"column medium-6 small-12\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"work.show\",[22,1,[\"slug\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[12,\"class\",[28,[\"grid-item grid-item-\",[22,2,[]]]]],[12,\"style\",[28,[\"background-image: url(\",[22,1,[\"featuredImage\",\"url\"]],\")\"]]],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"grid-item-overlay\"],[9],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"grid-item-inset\"],[9],[10],[0,\"\\n          \"],[7,\"div\"],[12,\"class\",[28,[\"svg-container-project \",[22,1,[\"slug\"]]]]],[9],[0,\"\\n            \"],[1,[27,\"get-svg\",[[22,1,[\"companyLogo\",\"url\"]]],[[\"slug\"],[[23,[\"company\",\"slug\"]]]]],false],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "collective-ember-quickstart/templates/components/project-tiles.hbs"
    }
  });

  _exports.default = _default;
});